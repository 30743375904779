import { default as Index51OHmj3ZHBMeta } from "@/src/pages/Index.vue?macro=true";
import { default as IndexA8arFgovlSMeta } from "@/src/pages/promotion/contact/Index.vue?macro=true";
import { default as IndexM9Ces0hCKyMeta } from "@/src/pages/promotion/about/Index.vue?macro=true";
import { default as IndexCkJQ7F71waMeta } from "@/src/pages/promotion-temp1/about/Index.vue?macro=true";
import { default as IndexBWkNrcNvHyMeta } from "@/src/pages/promotion-temp1/contact/Index.vue?macro=true";
import { default as IndexE4JuFONPSvMeta } from "@/src/pages/promotion-temp1/case-show/Index.vue?macro=true";
import { default as IndexKG3JSzfdlBMeta } from "@/src/pages/promotion-temp1/products/Index.vue?macro=true";
import { default as IndexqzUqb0i2NxMeta } from "@/src/pages/promotion-temp1/feedback/Index.vue?macro=true";
import { default as IndexHJdkEyuNbJMeta } from "@/src/pages/promotion-temp1/news/Index.vue?macro=true";
import { default as DetailTeMc2GUNQnMeta } from "@/src/pages/promotion-temp1/news/detail/Detail.vue?macro=true";
import { default as Indexl9Pzi3lWi1Meta } from "@/src/pages/nact/group/Index.vue?macro=true";
import { default as DetailcDxGfonZc8Meta } from "@/src/pages/nact/group/Detail.vue?macro=true";
export default [
  {
    name: Index51OHmj3ZHBMeta?.name ?? "Home",
    path: Index51OHmj3ZHBMeta?.path ?? "/",
    meta: Index51OHmj3ZHBMeta || {},
    alias: Index51OHmj3ZHBMeta?.alias || [],
    redirect: Index51OHmj3ZHBMeta?.redirect,
    component: () => import("@/src/pages/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexA8arFgovlSMeta?.name ?? "PromotionContact",
    path: IndexA8arFgovlSMeta?.path ?? "/front/contact",
    meta: IndexA8arFgovlSMeta || {},
    alias: IndexA8arFgovlSMeta?.alias || [],
    redirect: IndexA8arFgovlSMeta?.redirect,
    component: () => import("@/src/pages/promotion/contact/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexM9Ces0hCKyMeta?.name ?? "PromotionAbout",
    path: IndexM9Ces0hCKyMeta?.path ?? "/front/about",
    meta: IndexM9Ces0hCKyMeta || {},
    alias: IndexM9Ces0hCKyMeta?.alias || [],
    redirect: IndexM9Ces0hCKyMeta?.redirect,
    component: () => import("@/src/pages/promotion/about/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexCkJQ7F71waMeta?.name ?? "Promotion1AboutCd",
    path: IndexCkJQ7F71waMeta?.path ?? "/prom-t1/about",
    meta: IndexCkJQ7F71waMeta || {},
    alias: IndexCkJQ7F71waMeta?.alias || [],
    redirect: IndexCkJQ7F71waMeta?.redirect,
    component: () => import("@/src/pages/promotion-temp1/about/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexBWkNrcNvHyMeta?.name ?? "Promotion1ContactCd",
    path: IndexBWkNrcNvHyMeta?.path ?? "/prom-t1/contact",
    meta: IndexBWkNrcNvHyMeta || {},
    alias: IndexBWkNrcNvHyMeta?.alias || [],
    redirect: IndexBWkNrcNvHyMeta?.redirect,
    component: () => import("@/src/pages/promotion-temp1/contact/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexE4JuFONPSvMeta?.name ?? "Promotion1CaseShow",
    path: IndexE4JuFONPSvMeta?.path ?? "/prom-t1/case-show",
    meta: IndexE4JuFONPSvMeta || {},
    alias: IndexE4JuFONPSvMeta?.alias || [],
    redirect: IndexE4JuFONPSvMeta?.redirect,
    component: () => import("@/src/pages/promotion-temp1/case-show/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexKG3JSzfdlBMeta?.name ?? "Promotion1Products",
    path: IndexKG3JSzfdlBMeta?.path ?? "/prom-t1/products",
    meta: IndexKG3JSzfdlBMeta || {},
    alias: IndexKG3JSzfdlBMeta?.alias || [],
    redirect: IndexKG3JSzfdlBMeta?.redirect,
    component: () => import("@/src/pages/promotion-temp1/products/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexqzUqb0i2NxMeta?.name ?? "Promotion1Feedback",
    path: IndexqzUqb0i2NxMeta?.path ?? "/prom-t1/feedback",
    meta: IndexqzUqb0i2NxMeta || {},
    alias: IndexqzUqb0i2NxMeta?.alias || [],
    redirect: IndexqzUqb0i2NxMeta?.redirect,
    component: () => import("@/src/pages/promotion-temp1/feedback/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexHJdkEyuNbJMeta?.name ?? "Promotion1News",
    path: IndexHJdkEyuNbJMeta?.path ?? "/prom-t1/news",
    meta: IndexHJdkEyuNbJMeta || {},
    alias: IndexHJdkEyuNbJMeta?.alias || [],
    redirect: IndexHJdkEyuNbJMeta?.redirect,
    component: () => import("@/src/pages/promotion-temp1/news/Index.vue").then(m => m.default || m)
  },
  {
    name: DetailTeMc2GUNQnMeta?.name ?? "Promotion1NewsDetail",
    path: DetailTeMc2GUNQnMeta?.path ?? "/prom-t1/news/:newsId(\\id)",
    meta: DetailTeMc2GUNQnMeta || {},
    alias: DetailTeMc2GUNQnMeta?.alias || [],
    redirect: DetailTeMc2GUNQnMeta?.redirect,
    component: () => import("@/src/pages/promotion-temp1/news/detail/Detail.vue").then(m => m.default || m)
  },
  {
    name: Indexl9Pzi3lWi1Meta?.name ?? "NactGroupIndex",
    path: Indexl9Pzi3lWi1Meta?.path ?? "/nact/group/index",
    meta: Indexl9Pzi3lWi1Meta || {},
    alias: Indexl9Pzi3lWi1Meta?.alias || [],
    redirect: Indexl9Pzi3lWi1Meta?.redirect,
    component: () => import("@/src/pages/nact/group/Index.vue").then(m => m.default || m)
  },
  {
    name: DetailcDxGfonZc8Meta?.name ?? "NactGroupDetail",
    path: DetailcDxGfonZc8Meta?.path ?? "/nact/group/detail",
    meta: DetailcDxGfonZc8Meta || {},
    alias: DetailcDxGfonZc8Meta?.alias || [],
    redirect: DetailcDxGfonZc8Meta?.redirect,
    component: () => import("@/src/pages/nact/group/Detail.vue").then(m => m.default || m)
  }
]